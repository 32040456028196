<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-card class="pt-6 pb-12 px-6">
        <v-row>
          <v-col cols="12">
            <h2 class="title">{{ $t('document.category.create_title') }}</h2>
          </v-col>
          <v-col>
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="form.name"
                      outlined
                      hide-details
                      :label="$t('document.category.name')"
                      :placeholder="$t('document.category.name')"
                      required
                      :error="errors.name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-row class="mt-3 px-3">
                  <v-col cols="12" md="6" lg="4">
                    <v-select
                        v-model="form.employee"
                        :label="$t('document.category.employee')"
                        :items="combobox"
                        outlined
                        hide-details
                        :append-icon="'mdi-chevron-down'"
                        :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-select
                        v-model="form.customer"
                        :label="$t('document.category.customer')"
                        :items="combobox"
                        outlined
                        hide-details
                        :append-icon="'mdi-chevron-down'"
                        :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-select
                        v-model="form.can_delete"
                        :label="$t('document.category.can_delete')"
                        :items="combobox"
                        outlined
                        hide-details
                        :append-icon="'mdi-chevron-down'"
                        :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-3 px-3">
                  <v-col cols="12" md="12" lg="6" class="pr-0">
                    <v-btn
                        style="width: 100%; padding: 28px;"
                        elevation="0"
                        color="green"
                        @click="createDocumentCategory"
                        :loading="btn_create_loading"
                        :disabled="btn_create_loading"
                    >
                      {{ $t('document.category.buttons.save') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12" lg="6">
                    <v-btn style="width: 100%; padding: 28px;" elevation="0" @click="backToDocumentCategories">
                      {{ $t('document.category.buttons.abort') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DocumentCategories",
  data() {
    return {
      preloader: true,
      btn_create_loading: false,
      form: {
        name: '',
        employee: 1,
        customer: 0,
        can_delete: 1,
      },
      combobox: [
        {
          text: this.$t('document.category.combobox.yes'),
          value: 1
        },
        {
          text: this.$t('document.category.combobox.no'),
          value: 0
        },
      ],
      errors: []
    }
  },
  created() {
    setTimeout( () => {this.preloader =false}, 300)
  },
  watch: {
    'form.employee'() {
      if (this.form.employee === 0 && this.form.customer !== 1) {
        this.form.customer = 1
      }
    },
    'form.customer'() {
      if (this.form.customer === 0 && this.form.employee !== 1) {
        this.form.employee = 1
      }
    }
  },
  methods: {
    createDocumentCategory() {
      this.btn_create_loading = true
      this.$store.dispatch('documentCategoryCreate', this.form)
          .then(() => {this.backToDocumentCategories()})
          .catch(error => {
            switch (error.response.status) {
              case 422:
                this.errors = error.response.data.errors;
                this.btn_create_loading = false
                break;
            }
          })
    },
    backToDocumentCategories() {
      this.$router.replace({
        name: 'documents.categories'
      })
    },
  }
}
</script>

<style scoped>

</style>
